import { ReactNode, useContext, useEffect, useRef, useState } from 'react'
import {
  Carousel,
  // @ts-ignore: fix carousel types
  useCarousel,
  FlexGrid,
  Icon,
  Spacer,
  StackView,
  Tabs,
  Typography,
  useResponsiveProp,
} from '@telus-uds/components-web'

import Palette from '@telus-uds/palette-allium/build/web/palette'
import WithEarlyReturn from '@/utils/WithEarlyReturn'
import { SiteBuilderContext } from '@/siteBuilder/renderer/context/SiteBuilderContext'
import { getIcon } from '@/siteBuilder/utils/getIcon'
import { findItem } from '@/siteBuilder/utils/findItem'
import { EqualColumnHeightContainer } from '@/siteBuilder/blocks/core/Column/Styles'
import Image from '@/siteBuilder/blocks/core/Image/Image'
import TriageTabsContainer from './styles'

export interface ITriageTabItemProps {
  label: string
  href: string
  id: string
  content: JSX.Element
  icon?: string
  iconImageSrc?: string
}

export interface TriageTabsProps {
  tabItems?: ITriageTabItemProps[]
  animated?: boolean
  variant?: string
}

const renderWithEqualHeight = (children: ReactNode): ReactNode => (
  <EqualColumnHeightContainer>{children}</EqualColumnHeightContainer>
)

const TriageTabs = ({ tabItems, animated = false, variant }: TriageTabsProps) => {
  const carouselGoTo = useRef<object>(null)
  const purpleVariant = variant === 'purple'
  const tabId = tabItems.reduce((acc, curr) => acc + curr.id, '')

  const { setActiveTabIds } = useContext(SiteBuilderContext)
  const [selectedId, setSelectedId] = useState<string>(undefined)
  const [carouselAnimation, setCarouselAnimation] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(undefined)
  const [useAnimation, setUseAnimation] = useState<boolean>(false)
  const [hover, setHover] = useState<string>(undefined)
  const maxWidth = useResponsiveProp({ xs: 200, md: 9999 }) // `md` value is effectively 'none', but itemTokens.maxWidth is typed as number
  const labelTextSize = useResponsiveProp(
    purpleVariant ? { xs: 'small', md: 'medium', lg: 'large' } : { xs: 'small', md: 'normal' }
  )
  const iconSize = useResponsiveProp(
    purpleVariant ? { xs: 'normal', md: 'large', xl: 'extraLarge' } : { xs: 'normal', md: 'large', xl: 'extraLarge' }
  )
  const iconSizePx = useResponsiveProp(purpleVariant ? { xs: 24, md: 32, xl: 42 } : { xs: 24, md: 32, xl: 48 })
  const paddingVertical = useResponsiveProp(purpleVariant ? { xs: 8 } : { xs: 8, md: 16, xl: 24 })

  const tabHasIcon = (item: ITriageTabItemProps) => {
    const { icon, iconImageSrc } = item
    const hasUDSIcon = icon ? getIcon(icon) : undefined
    const hasImageIcon = iconImageSrc || undefined
    return Boolean(hasUDSIcon || hasImageIcon)
  }

  const anyTabHasIcon = tabItems.some(tabHasIcon)

  const renderIcon = (item: ITriageTabItemProps): JSX.Element => {
    if (!anyTabHasIcon) return undefined
    if (!tabHasIcon(item)) return <Spacer space={{ xs: 4, md: 5, xl: 7 }} testID="triage-tabs-blank-icon" />
    const { icon, iconImageSrc } = item
    const udsIcon = icon ? getIcon(icon) : undefined
    if (udsIcon) {
      return <Icon variant={{ rank: 'primary', size: iconSize }} icon={udsIcon} testID="triage-tabs-uds-icon" />
    }
    return (
      <Image
        src={iconImageSrc}
        width={iconSizePx}
        height={iconSizePx}
        placeholder="empty"
        alt=""
        data-testid="triage-tabs-image-icon"
      />
    )
  }

  useEffect(() => {
    if (!selectedId) {
      if (window.location.hash) {
        const selectedId = findItem(tabItems, window.location.hash.replace('#', ''))?.id
        if (selectedId) return setSelectedId(selectedId)
      }
      setSelectedId(tabItems[0].id)
    }
  }, [])

  useEffect(() => {
    setTimeout(
      () => {
        if (typeof carouselGoTo.current === 'function') {
          const id = tabItems.findIndex((t) => {
            return t.id === selectedId
          })

          if (id > -1) {
            carouselGoTo.current(id)
            if (animated !== useAnimation) {
              setTimeout(() => {
                setUseAnimation(animated)
              }, 500)
            }
          }
          if (!show) {
            setTimeout(() => {
              setShow(true), 500
            })
          }
        }
      },
      show ? 0 : 700
    )
    setActiveTabIds(tabId, selectedId)
  }, [selectedId])

  function SyncCarouselHook(): JSX.Element {
    const { goTo } = useCarousel()
    carouselGoTo.current = goTo
    return null
  }

  const renderLabel = (id: string, label: string, icon: JSX.Element) => (
    <div
      onMouseEnter={() => setHover(id)}
      onMouseLeave={() => {
        if (id) setHover(undefined)
      }}
    >
      <StackView space={{ xs: 2, md: 3 }} tokens={{ alignItems: 'center' }}>
        {icon}
        <Typography
          variant={{
            colour: purpleVariant ? 'brand' : undefined,
            size: labelTextSize,
            bold: selectedId === id || hover === id,
          }}
        >
          {label}
        </Typography>
      </StackView>
    </div>
  )
  const springConfig = useAnimation ? { stiffness: 100, damping: 200 } : { speed: 1000 }
  return (
    <TriageTabsContainer className="triage-tabs" data-testid="triageTabs">
      {show && <div data-testid="tabContainerLoaded" style={{ display: 'none' }} />}
      <FlexGrid gutter={false}>
        <FlexGrid.Row>
          <FlexGrid.Col>
            <Tabs
              tokens={{ borderBottomColor: Palette.color.dark20 }}
              itemTokens={{
                maxWidth,
                highlightColor: 'unset',
                backgroundColor: Palette.color.white,
                borderColor: Palette.color.white,
                borderWidth: 0,
                paddingHorizontal: 8,
                paddingVertical,
                space: 0,
              }}
              onChange={(id: string) => {
                if (animated === useAnimation) {
                  setSelectedId(id)
                }
              }}
              value={selectedId ?? tabItems[0].id}
              items={tabItems.map((item) => {
                return {
                  id: item.id,
                  label: renderLabel(item.id, item.label, renderIcon(item)),
                  href: '#' + item.id,
                  content: renderWithEqualHeight(item.content),
                }
              })}
            />
            <Carousel
              onAnimationStart={() => {
                setCarouselAnimation(true)
              }}
              onAnimationEnd={() => {
                setCarouselAnimation(false)
              }}
              springConfig={springConfig}
              tokens={{ showPreviousNextNavigation: false, showPanelNavigation: false }}
            >
              {tabItems?.map((tab) => (
                <Carousel.Item key={tab.id}>
                  <SyncCarouselHook />
                  <div
                    data-triage-tab-id={tab.id}
                    className={selectedId === tab.id ? 'active' : 'inactive'}
                    style={{
                      visibility:
                        (tab.id === selectedId && !carouselAnimation && !useAnimation) || useAnimation
                          ? 'visible'
                          : 'hidden',
                      maxHeight: tab.id === selectedId ? '100%' : '1px',
                    }}
                  >
                    {tab.content}
                    {tab.id === selectedId && !carouselAnimation && !useAnimation && (
                      <div data-testid={`${tab.id}Loaded`} style={{ display: 'none' }} />
                    )}
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
    </TriageTabsContainer>
  )
}

const isValid = (tabs: ITriageTabItemProps[]): boolean => {
  if (!tabs || tabs.length === 0) {
    return false
  }
  return true
}

export default WithEarlyReturn(TriageTabs, 'tabItems', isValid, 'No tabs to render')
